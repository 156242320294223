if( navigator.platform != "Linux x86_64" ) {

      window.rs_render_target = '';
      window.rs_data = window.rs_data || {};
      window.rs_data['widget_1994'] = function(key, defaultValue) {
          const data = {
              "name": "home page",
              "locale": "en",
              "widgetType": "Carousel",
              "maxReviews": 25,
              "displayMode": 1,
              "showRequestButton": false,
              "width": 0,
              "height": 0,
              "displayElements": [0, 2, 3, 4],
              "settings": "",
              "styling": "simple",
              "backgroundColor": "rgba(216,211,189,1)",
              "reviewBackgroundColor": "rgba(255,255,255,0.98)",
              "textColor": "rgba(0,0,0,1)",
              "linkColor": "rgba(208,2,27,1)",
              "buttonColor": "rgba(0,0,0,100)",
              "starColor": "rgba(127,96,0,100)",
              "titleFontSize": "20",
              "titleFontWeight": "0",
              "bodyFontSize": "14",
              "customCSS": ".reviewshakeWidget__reviewBottom {padding:.2em;font-size:.8em; justify-content:flex-end; } .reviewshakeWidget__reviewUserProfile__name {font-size:1em; } .reviewshakeWidget__reviewUserProfile {margin-bottom:1em;} .reviewshakeWidget__review .reviewshakeWidget__sourcePic { max-height:1.25em;} .reviewshakeWidget__reviewFooter {margin-top:1.5em;} .reviewshakeWidget__logos {visibility:hidden;}",
              "exReviewSource": [],
              "exStarRating": [1, 2, 3],
              "exEmptyReviews": true,
              "showSummaryHeader": false,
              "isRichSnippet": false,
              "richSnippetType": "LocalBusiness",
              "richSnippetMetaData": {},
              "ratingValue": 4.5,
              "ratingCount": 287,
              "locationName": "Rize Reviews",
              "businessName": "Baabuk",
              "image": document.getElementsByTagName("img")[0] ?
                  document.getElementsByTagName("img")[0].src :
                  "https://s3.amazonaws.com/reviewmanagement/review_us.png"
          }

          return data[key] !== undefined && data[key] !== '' ? data[key] : defaultValue;
      };

      (function() {
          const getStyle = function() {
              const id = '1994';
              const widgetID = 'rs-widget-' + id;
              const widgetDataID = 'widget_' + id;

              const namespace = 'reviewshakeWidget';

              const customCSS             = window.rs_data[widgetDataID]('customCSS', '');
              const linkColor             = window.rs_data[widgetDataID]('linkColor', 'rgba(33,150,243,100)');
              const textColor             = window.rs_data[widgetDataID]('textColor', 'rgba(39,39,39,100)');
              const backgroundColor       = window.rs_data[widgetDataID]('backgroundColor', 'rgba(255,255,255,0)');
              const reviewBackgroundColor = window.rs_data[widgetDataID]('reviewBackgroundColor', 'rgba(255,255,255,100)');
              const titleBoldFont         = window.rs_data[widgetDataID]('titleFontWeight', "0").toString() == "0" ? 'normal' : 'bold';
              const titleFontSize         = window.rs_data[widgetDataID]('titleFontSize', 22);
              const bodyFontSize          = window.rs_data[widgetDataID]('bodyFontSize', 14);

              var mainStyle = '';

              mainStyle += ` .rs-widget-container#${widgetID} { background-color: ${backgroundColor}; color: ${textColor}; } `;
              mainStyle += ` .rs-widget-container#${widgetID} a { color: ${linkColor}; } `;
              mainStyle += ` .rs-widget-container#${widgetID} .${namespace}__review { background-color: ${reviewBackgroundColor}; } `;
              mainStyle += ` .rs-widget-container#${widgetID} .${namespace}__reviewText, .${namespace}__quoteText { font-size: ${bodyFontSize}px; } `;

              const fontWeight = titleBoldFont === 0 ? 'normal' : 'bold';
              mainStyle += ` .rs-widget-container#${widgetID} .${namespace}__averageTitle { font-size: ${titleFontSize}px; font-weight: ${fontWeight}; } `;

              const styleTag = document.createElement('style');
              styleTag.id = widgetID + '-css';
              styleTag.innerHTML = mainStyle + customCSS;
              return styleTag;
          }

          const getScript = function() {
              const id = '1994';
              const widgetDataID = 'widget_' + id;
              const widgetRichSnippetType = window.rs_data[widgetDataID]('richSnippetType', 'LocalBusiness');
              const widgetRichSnippetMetaData = window.rs_data[widgetDataID]('richSnippetMetaData', {});
              const address = widgetRichSnippetMetaData["address"];
              const price = widgetRichSnippetMetaData["price"];

              const query = {
                  "@context": "http://schema.org",
                  "@type": widgetRichSnippetType,
                  "@id": 'rs_widget_1994',
                  "name": window.rs_data[widgetDataID]('businessName', ''),
                  "image": window.rs_data[widgetDataID]('image', ''),
                  "aggregateRating": {
                      "@type": "AggregateRating",
                      "ratingValue": window.rs_data[widgetDataID]('ratingValue', ''),
                      "ratingCount": window.rs_data[widgetDataID]('ratingCount', '')
                  }
              };

              if (address && widgetRichSnippetType === "LocalBusiness") {
                  query['address'] = {
                      "@type": "PostalAddress",
                      "streetAddress": address["streetAddress"],
                      "addressLocality": address["addressLocality"],
                      "addressRegion": address["addressRegion"],
                      "postalCode": address["postalCode"],
                      "addressCountry": address["addressCountry"],
                  }
              } else if (price && widgetRichSnippetType === "Product") {
                  query['offers'] = {
                      "@type": "Offer",
                      // "url": "https://example.com/product",
                      "priceCurrency": "USD",
                      "price": price,
                      "itemCondition": "https://schema.org/NewCondition",
                      "availability": "https://schema.org/InStock"
                  }
              }

              const script = document.createElement('script');
              script.type = "application/ld+json";
              script.innerHTML = JSON.stringify(query);

              return script;
          };

          // Teefan: attempt to inject jQuery if not present - requested by front-end dev
          const embedJQuery = function(callback) {
              var url = 'https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js';
              var position = 0;

              // Check is jQuery exists
              if (!window.jQuery) {
                  // Initialize <head>
                  var head = document.getElementsByTagName('head')[0];
                  // Create <script> element
                  var script = document.createElement('script');
                  // Append URL
                  script.src = url;
                  // Append type
                  script.type = 'text/javascript';
                  // Append script to <head>
                  head.appendChild(script);
                  // Move script on proper position
                  head.insertBefore(script,head.childNodes[position]);

                  script.onload = function () {
                      if (typeof callback === 'function') {
                          callback(jQuery);
                      }
                  };
              } else {
                  if (typeof callback === 'function') {
                      callback(jQuery);
                  }
              }
          }

          const render = function(script) {
              document.getElementsByTagName('head')[0].appendChild(script);
          };

          const init = function() {
              const id = '1994';
              const widgetDataID = 'widget_' + id;

              const defaultStyleLink = document.createElement('link');
              defaultStyleLink.media = 'screen';
              defaultStyleLink.rel = 'stylesheet';
              defaultStyleLink.href = '//rsvp-prod.s3.amazonaws.com/assets/widget/common_widget/widget-5bad0897f065611b0594ab5e67786aee2687e396a7ff0b1ddfec52a65a138615.css';
              render(defaultStyleLink);

              const styles = getStyle();
              render(styles);

              if (!window.rs_data[widgetDataID]('isRichSnippet', false)) return false;
              const script = getScript();

              render(script);
          };

          // init();
          embedJQuery(init);
      }());


    document.write("\n<div\n  id=\'rs-widget-1994\'\n  class=\'rs-widget-container\'\n  \n  data-host=\'https://www.rize.reviews\'\n  data-params=\'{&quot;client_id&quot;:&quot;1093&quot;,&quot;org&quot;:&quot;1&quot;,&quot;type&quot;:&quot;carousel&quot;}\'\n  data-options=\'{}\'\n><\/div>\n<script src=\"//rsvp-prod.s3.amazonaws.com/packs-build/js/widget_init-421fceecf819bb81947c.js\"><\/script>\n\n");



}